import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { User } from '../models/classes/user/user';
import { environment } from '../../environments/environment';
import { AuthService as AuthSocial } from 'angularx-social-login';
import { GlobalService } from './global.service';
import { CustomerEnum } from '../models/enum/customer.enum';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  nanoStoreInUse: boolean = false;
  authSubject = new BehaviorSubject(false);

  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public token: string = undefined;
  public room: string = undefined;
  public door: string = undefined;
  public personalization = CustomerEnum.DEFAULT

  constructor(
      private httpClient: HttpClient,
      private _socioAuthServ: AuthSocial,
      private globalService: GlobalService
  ) {
      this.loadPersonalization();
      this.currentUserSubject = new BehaviorSubject<User>(
          (localStorage.getItem('currentUser') == null) ? null : new User(JSON.parse(localStorage.getItem('currentUser')))
      );
      this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
      return this.currentUserSubject.value;
  }

  loadPersonalization() {    
      let currentUrl = window.location.href.replace("www.", "");
      let customUrlPrefix = currentUrl.substring(currentUrl.indexOf("://") + 3, currentUrl.indexOf("."));
    
      let enumValues = Object.values(CustomerEnum);
      for (let i in enumValues) {
          if (customUrlPrefix.startsWith(enumValues[i])) {
              this.personalization = enumValues[i];
              return;
          }
      }
      this.personalization = CustomerEnum.DEFAULT;
  }


  login(form: any) {
    if (localStorage.currentUser) {
      localStorage.clear();
    }

    let body = new FormData();
    body.append('email', form.email);
    body.append('password', form.password);
    if(form.token){
      body.append('token', form.token);
    }

    return this.httpClient.post<any>(`${environment.apiUrl}login`, body);
  }

  openIntentPopup() {
    return Swal.fire({
      title: 'Empreinte bancaire',
      html: `<div style="color:black">
        <p>
          Afin d'utiliser ce service, une <a href="https://help.shine.fr/fr/articles/2311904-a-quoi-sert-une-empreinte-de-carte-bancaire" crossorigin="anonymous" target="_blank">empreinte bancaire</a> de 500€ sera effectuée sur votre compte.
        </p>
        <p>Cette empreinte ne sera pas prélevée de votre compte, seul le montant du panier le sera.</p>
      </div>`,
      icon: 'info',
      allowOutsideClick: false,
      backdrop: false,
      customClass: {
        popup: 'my-swal',
        content: 'p-0'
      },
      confirmButtonText: 'Compris !',
    });
  }

  register(user): Observable<any> {
    if(localStorage.justRegistered){
      localStorage.clear();
    } 
    let userArray = Object.entries(user);
    let userObj = new FormData();
    
    userArray.forEach((u:any) => {
      userObj.append(u[0] , u[1]);
    });

    return this.httpClient.post<any>(`${environment.apiUrl}register`, userObj);
  }

  socialAuth(data, onSuccess = (success) => {}) {
    console.log("Start authentification via social media")

    if (localStorage.currentUser) {
      localStorage.clear();
    }


    let userObj = new FormData(
    );
    userObj.append("email", data.email);
    userObj.append("photoUrl", data.photoUrl);

    let socialPhotoUrl = data.photoUrl;

    return this.httpClient.post<User>(`${environment.apiUrl}socialAuth`, userObj)
      .pipe(map((user: any) => {
        console.log("Receive authentification response")
        if (user.status == "error") {
          this.currentUserSubject.next(null);
        } else{
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem(`socialPhotoUrl`, socialPhotoUrl);
          this.globalService.changeAppTheme(user);
          this.currentUserSubject.next(user);
        }

        return user;
      }))

  }

  loadUserDatas(user: User, token:string) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.globalService.changeAppTheme(user);
    this.currentUserSubject.next(user);

    document.documentElement.style.setProperty('--primary', user.color);
    sessionStorage.setItem('login_token', token);
  }

  logout() {
    this._socioAuthServ.signOut();
    localStorage.clear();
    this.currentUserSubject.next(null);
    location.reload();
  }
}
