// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: "https://test.livepick.ai/",
  AzureRedirectUri: "https://test.app.livepick.ai", 
  google_auth_client_id: "1088177631529-2p71qppqsetlg6aospbniqh8bn72e2gf.apps.googleusercontent.com",
  facebook_auth_client_id: "357359291637009",
  generatePhotoUrl: "https://eu.ui-avatars.com/api/?background=DFD22B&color=fff&name=",
  socketUrl : "wss://test.livepick.ai:5678/",
};
  
/* 
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
